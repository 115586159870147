const TOKEN = 'accessToken';

export const getAccessToken = () => {
  return JSON.parse(localStorage.getItem(TOKEN) as string);
};

export const setAccessToken = (token: string) => {
  localStorage.setItem(TOKEN, JSON.stringify(token));
};

export const removeAccessToken = () => {
  localStorage.removeItem(TOKEN);
};
