import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { useStores } from '../utils/hooks/useStores';

import { createStyles, makeStyles } from '@material-ui/core/styles';
import {
    ButtonBase,
    IconButton,
    InputAdornment,
    TextField
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import fontSizes from '../assets/styles/fontSizes';
import { Route } from '../utils/enums/routes';
import colors from '../assets/styles/colors';

const useStyles = makeStyles(theme =>
    createStyles({
        root: {
            '& > *': {
                margin: theme.spacing(1),
                width: '100%'
            }
        },
        wrapper: {
            padding: '60px 80px',
            [theme.breakpoints.down('md')]: {
                padding: '15px 25px 15px 15px'
            }
        },
        submit: {
            marginTop: 50
        },
        signUp: {
            marginTop: 10,
            textAlign: 'center',
            fontSize: fontSizes.paragraph2
        },
        button: {
            padding: '17px 0',
            fontWeight: 'bold',
            textAlign: 'center',
            letterSpacing: '0.075em',
            textTransform: 'uppercase',
            color: colors.white,
            backgroundColor: theme.palette.secondary.main,
            fontSize: fontSizes.h5,
            width: '100%',
            marginTop: 50
        },
        disabled: {
            backgroundColor: colors.lightVioletDisabled
        }
    })
);
interface IProps {}

const Wrapper = styled.div`
  position: absolute;
  left: 50%;
  max-width: 400px;
  transform: translate(-50%);
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const Error = styled.span`
  margin-top: 11px;
  color: ${colors.error};
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
`

const Login: React.FC<IProps> = () => {
    const classes = useStyles();
    const { push } = useHistory();
    const [email, setEmail] = useState('');
    const [error, setError] = useState<string>('')
    const [password, setPassword] = useState('');
    const { userStore } = useStores();
    const [passwordVisible, setPasswordVisible] = useState(false);
    

    const handleClickShowPassword = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleMouseDownPassword = (event: React.MouseEvent) => {
        event.preventDefault();
    };

    const handleSubmit =(e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        userStore
            .userLogin(email, password)
            .then(res => {
                push(Route.DASHBOARD);
            })
            .catch(error => {
                setError('Wrong username/password')
            });
    };

    return (
        <div>
            <Wrapper className={classes.wrapper}>
                <Header>
                    <h1>Login</h1>
                </Header>
                <div>
                    <form
                        className={classes.root}
                        onSubmit={handleSubmit}
                        color="primary"
                    >
                        <TextField
                            required
                            id="email"
                            type="email"
                            label="Email"
                            value={email}
                            color="secondary"
                            placeholder="Type your email"
                            onInput={e => {
                                const target = e.target as HTMLInputElement;
                                setEmail(target.value);
                            }}
                        />
                        <TextField
                            required
                            id="password"
                            label="Password"
                            value={password}
                            color="secondary"
                            placeholder="Type your password"
                            type={passwordVisible ? 'text' : 'password'}
                            onInput={e => {
                                const target = e.target as HTMLInputElement;
                                setPassword(target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            onMouseDown={handleMouseDownPassword}
                                            disableRipple
                                        >
                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                         <Error>{error}</Error>
                        <ButtonBase
                            disabled={!(email.length > 0 && password.length > 0)}
                            type="submit"
                            classes={{
                                root: classes.button,
                                disabled: classes.disabled
                            }}
                        >
                            Login
                        </ButtonBase>
                    </form>
                </div>
            </Wrapper>
        </div>
    );
};

export default Login;
