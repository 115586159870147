import axios from 'axios';
import { getAccessToken} from '../../src/utils/token';

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});



instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response && error.response.status === 401) {
      // window.location.pathname = `/${Route.LOGIN}`;
    }
    return Promise.reject();
  }
);

export const sAxios = axios.create({ baseURL: process.env.REACT_APP_API_URL });
sAxios.interceptors.request.use(
  config => {
    // if (isTokenValid()) {
      config.headers.Authorization = getAccessToken();
      return config;
    // }
    // return Promise.reject(new Error('Auth Required'));
  },
  error => {
    return Promise.reject(error);
  }
);
sAxios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (!error.response || error.response.status === 401) {
      // removeAccessToken();
      // delete sAxios.defaults.headers.common['Authorization'];
      // window.location.pathname = '/';
    }
    return Promise.reject(error);
  }
);

export default instance;
