export default {
  hero: '48px',
  hero1: '8.75rem',
  h1: '32px',
  h2: '24px',
  h3: '18px',
  h4: '1rem',
  h5: '0.875rem',
  h6: '10px',
  h7: '0.625rem',
  h8: '0.5rem',
  body: '0.875rem',
  paragraph: '16px',
  paragraph1: '18px',
  paragraph2: '14px',
  paragraph3: '0.875rem',
  button: '12px',
  link: '0.875rem',
  caption: '0.75rem',
  subtitle: '0.75rem',
  tooltip: '0.625rem',
  dropdownTitle: '0.625rem',
  loading: '1.125rem',
  calendarTitle: '1rem',
  title: '2.3125rem'
};
