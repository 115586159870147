import api, { sAxios } from '../../src/utils/api';
import endpoints from '../../src/utils/constants/endpoints';

export const userLogin = (username: string, password: string) =>
  api({
    method: 'POST',
    url: endpoints.login,
    data: { email: username, password }
  });

  export const uploadFile = (file: string ,id: string) =>
  sAxios({
    method: 'PUT',
    url: endpoints.updateFile(id),
    data: { logo: file }
  }); 

export const updateUser = (id: string, subscription: string, username: string, password: string, identifier: string) =>
  sAxios({
    method: 'PUT',
    url: endpoints.update(id),
    data: { subscription, username, password, identifier }
  });

export const fetchUsers = (limit: number, page: number) =>
  sAxios({
    method: 'GET',
    url: endpoints.getUsers,
    params: {
      limit,
      page
    }
  });

export const testBusiness = (email: string) =>
  sAxios({
    method: 'POST',
    url: endpoints.testBusiness(email),
  });

export const testConsumer = (email: string) =>
  sAxios({
    method: 'POST',
    url: endpoints.testConsumer(email),
  });

