import { action } from 'mobx';
import * as api from '../api';
import { AppStore, NotifyStore } from '.';

export class UserStore {
  constructor(private appStore: AppStore, private notify: NotifyStore) {}

  @action public userLogin = async (username: string, password: string) => {
    try {
      const response = await api.userLogin(username, password);
      this.appStore.setAccessToken(response.data.token);
    } catch (error) {
      this.notify.error(error.message);
    }
  };

  @action public update = async (id:string, subscription: string, username: string, password: string, identifier: string) => {
    try {
      return await api.updateUser(id, subscription, username, password, identifier);
    } catch (error) {
      this.notify.error(error.message);
    }
  };

  @action public uploadFile = async (file: string, id: string) => {
    try {
      return await api.uploadFile(file, id);
    } catch (error) {
      alert(error);
      return error;
      // this.notify.error(error.message);
    }
  };

  @action public getUsers = async (limit: number, page: number) => {
    try {
      const response = await api.fetchUsers(limit , page);
      return response.data;
    } catch (error) {
      alert(error.response.data);
      return error.response.data;
    }
  };

  @action public testBusiness = async (email: string) => {
    try {
      const response = await api.testBusiness(email);
      alert(response.data.status);
      return response.status;
    } catch (error) {
      alert(error.response.data.status);
      return error.response.status;
    }
  };

  @action public testConsumer = async (email: string) => {
    try {
      const response = await api.testConsumer(email);
      alert(response.data.status);
      return response.status;
    } catch (error) {
      alert(error.response.data.status);
      return error.response.status;
    }
  };
}
