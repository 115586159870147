import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Login from './routes/login';
import Dashboard from './routes/dashboard';

import * as serviceWorker from './serviceWorker';
import './assets/styles/main.css';

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Switch>
        <Route component={Login} exact path={'/login'} />
        <Route component={Dashboard} exact path={'/dashboard'} />
        <Route component={Login} exact path={'/'} />
      </Switch>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
