import { action, observable } from 'mobx';
import { setAccessToken } from '../utils/token';

export class AppStore {
  @observable public showPage: string = '';
  @observable public accessToken: string | null = null;
  @observable public refreshToken: string | null = null;

  @action public setAccessToken = (token: string | null) => {
    this.accessToken = token;
    if (token) {
      setAccessToken(token);
    } else {
      // removeAccessToken();
    }
  };

  @action public logout = () => {
    // this.setAccessToken(null);
  };
}
