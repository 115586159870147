import React, { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useStores } from '../../src/utils/hooks/useStores';

import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Modal from '@material-ui/core/Modal';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import TableRow from '@material-ui/core/TableRow';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TextField from '@material-ui/core/TextField';
import Container from '@material-ui/core/Container';
import FormControl from '@material-ui/core/FormControl';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';

import { Route } from '../utils/enums/routes';
import imageSrc from '../assets/images/logo.png';
import  {Component} from 'react';
import {DropzoneArea} from 'material-ui-dropzone';

const columns = [
  { id: 'name', label: 'Name', minWidth: 50 },
  { id: 'lastName', label: 'Last Name', minWidth: 50 },
  {
    id: 'email',
    label: 'Email',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'mclCount',
    label: 'MCL Count',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'subscription',
    label: 'Subscription',
    minWidth: 170,
    align: 'left',
  },
  {
    id: 'BusinessMCL',
    label: 'Business MCL',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'ConsumerMCL',
    label: 'Consumer MCL',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'UploadLogo',
    label: 'Upload Logo',
    minWidth: 100,
    align: 'right',
  },
  {
    id: 'logo',
    label: 'Logo',
    minWidth: 100,
    align: 'right',
  },

];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  test: {
    maxWidth: '2000px',
  },
  saveButton: {
    float: 'right',
    marginRight: '57px'
  },
  saveButtonLogo: {
    float: 'right',
    marginTop: '20px'
  },
  container: {
    maxHeight: 640,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  logo: {
    height: '40px',
    width: 'auto',
    maxWidth:'120px'
  },
  paper: {
    width: 400,
    position: 'absolute',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    backgroundColor: theme.palette.background.paper,
  },
  rootModal: {
    flexGrow: 1,
  },
}));

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function Dashboard() {
  const classes = useStyles();
  const { push } = useHistory();
  const { userStore } = useStores();

  const [page, setPage] = React.useState(0);
  const [rows, setRows] = React.useState([]);
  const [files, setFiles] = React.useState([]);
  const [user, setUser] = React.useState('');
  const [pass, setPass] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [interfaceMcl, setInterfaceMcl] = React.useState('');

  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);
  const [openUpload, setOpenUpload] = React.useState(false);
  // const handleOpen = () => {
  //   setOpen(true);
  // };

  const handleClose = () => {
    setOpen(false);
   
  };
  const handleCloseUpload = () => {
    setFiles([]);
    setOpenUpload(false);
   
  };

  const fileData = () => { 
     
    if (files.length === undefined) { 
        
      return ( 
        <div> 
          <h2>File Details:</h2> 
          <p>File Name: {files.name}</p> 
          <p>File Type: {files.type}</p> 
        </div> 
      ); 
    } else { 
      return ( 
        <div> 
          <br /> 
          <h4>Choose before Pressing the Upload button</h4> 
        </div> 
      ); 
    } 
  }; 
  
  const updateLogo = () => {
    if(files.length === undefined){
    var reader = new FileReader();
    reader.readAsBinaryString(files);

    reader.onload = function() {
        userStore.uploadFile(btoa(reader.result), userId).then(res => {
          let newArr = [...rows];
          const index = newArr.findIndex( ({ id }) => id === userId );
          newArr[index + (page * rowsPerPage)].logoPath = res.data.logoPath;
          setRows(newArr);
          setOpenUpload(false);
        });
    };
    reader.onerror = function() {
        console.log('there are some problems');
    };
     
      
    }else{
      fileData();
    }
    
   
  }

  const updateSubscriptionProd = () => {
    handleClose();
    let newArr = [...rows];
    const index = newArr.findIndex( ({ id }) => id === userId );

    const email = newArr[index + (page * rowsPerPage)].email;
    const oldSubscription = newArr[index + (page * rowsPerPage)].subscription;

    let testMcl = false;
    if (user === "" || pass === "" || interfaceMcl ===""){
      alert("Username, password and interface are Required!");
    } else {
      newArr[index + (page * rowsPerPage)].subscription = "Production";
      userStore.update(userId, "Production", user, pass, interfaceMcl).then(res => {
        userStore.testBusiness(email).then(res => {
          if(res === 200){
            testMcl = true;
          }
          userStore.testConsumer(email).then(res => {
            if (res === 200) {
              testMcl = true;
            }
            if (testMcl) {
              setRows(newArr);
              
            } else {
              userStore.update(userId, oldSubscription, "stitchcredit", "223435rm", "SmartAPITestingIdentifier").then(res => {
                newArr[index + (page * rowsPerPage)].subscription = oldSubscription;
                setRows(newArr);
                alert("Not ready to use MCL API for business or consumer report");
              });
            }
          })
        })
      });
    }
  };

  useEffect(() => {
    userStore.getUsers(200, 1).then(res => {
      if (res === "Unauthorized!"){
        push(Route.LOGIN);
      } else {
        setRows(res);
      }
    });
  }, [userStore, push]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeUser = (event) => {
    setUser( event.target.value)
  };
  const onFileChange = (event) => {
    console.log(event.target);
    console.log(event.target.files[0]);
    setFiles( event.target.files[0] );
  }
  const handleChangePass = (event) => {
    setPass( event.target.value)
  };

  const handleChangeInterface = (event) => {
    setInterfaceMcl( event.target.value)
  };

  const updateSubscription = index => e => {
    let newArr = [...rows];
    const id = newArr[index + (page * rowsPerPage)].id;
    if (e.target.value === "Production") {
      setUserId(id);
      setOpen(true);
    } else {
      userStore.update(id, e.target.value,"b","c","d").then(res => {
        newArr[index + (page * rowsPerPage)].subscription = e.target.value;
        setRows(newArr);
      });
    }
  };

  const testBusiness = index => e => {
    let newArr = [...rows];
    const email = newArr[index + (page * rowsPerPage)].email;
    userStore.testBusiness(email)
  };
  const uploadLogo = index => e => {
    let newArr = [...rows];
    const id = newArr[index + (page * rowsPerPage)].id;
    setUserId(id);
    setOpenUpload(true);
  };

  const testConsumer = index => e => {
    let newArr = [...rows];
    const email = newArr[index + (page * rowsPerPage)].email;
    userStore.testConsumer(email)
  };

  return (
    <Paper className={classes.root}>
      <Paper>
        <img title="logo" src={imageSrc} alt="logo" width="150px" height="70px" />
      </Paper>
      <Container  className={classes.test}>
      
      <h1 style={{marginLeft: '15px'}} >Users</h1>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody className={'tbody'}>
            {rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  {columns.map((column) => {
                    const value = row[column.id];
                    if (column.id === "subscription") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <FormControl className={classes.formControl}>
                            <Select
                              labelId="demo-simple-select-label"
                              id="subscription"
                              value={value}
                              onChange={updateSubscription(index)}
                            >
                              <MenuItem value={"Locked"}>Locked</MenuItem>
                              <MenuItem value={"Production"}>Production</MenuItem>
                              <MenuItem value={"Testing"}>Testing</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                      )
                    }
                    if (column.id === "BusinessMCL") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Button variant="outlined" color="primary" onClick={testBusiness(index)} >
                            Test
                          </Button>
                        </TableCell>
                      )
                    }
                    if (column.id === "ConsumerMCL") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Button variant="outlined" color="primary" onClick={testConsumer(index)} >
                            Test
                          </Button>
                        </TableCell>
                      )
                    }
                    if (column.id === "UploadLogo") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                          <Button variant="outlined" color="primary" onClick={uploadLogo(index)} >
                            Upload
                          </Button>
                        </TableCell>
                      )
                    }
                    if (column.id === "logo") {
                      return (
                        <TableCell key={column.id} align={column.align}>
                         <img className={classes.logo} src={row.logoPath} alt="My logo" />
                      </TableCell>
                      )
                    }
                    
                    return (
                      
                      <TableCell key={column.id} align={column.align}>
                        {column.format && typeof value === 'number' ? column.format(value) : value}
                      </TableCell>
                    );
                  })}
                </TableRow>
                
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      </Container>
      <div>
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
          <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">MCL Credentials</h2>
            <div className={classes.rootModal}>
              <Grid container spacing={3}>
                <Grid item xs={5}>
                  <p>Username</p>
                </Grid>
                <Grid item xs={7}>
                  <TextField value={user} required id="username" onChange={handleChangeUser}/>
                </Grid>
                <Grid item xs={5}>
                  <p>Password</p>
                </Grid>
                <Grid item xs={7}>
                  <TextField value={pass} required id="password" onChange={handleChangePass} />
                </Grid>
                <Grid item xs={5}>
                  <p>Interface</p>
                </Grid>
                <Grid item xs={7}>
                  <TextField value={interfaceMcl} required id="interface" onChange={handleChangeInterface}/>
                </Grid>
                <Grid item xs={5}>
                </Grid>
                <Grid item xs={7}>
                  <Button variant="outlined" color="primary"  className={classes.saveButton} onClick={() => {updateSubscriptionProd(this)}}  >
                    Save
                    </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Modal>
        <Modal
        open={openUpload}
        onClose={handleCloseUpload}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description">
          <div style={modalStyle} className={classes.paper}>
            <h2 id="simple-modal-title">Upload Logo</h2>
            <div className={classes.rootModal}>
            <div> 
            <div> 
                <input type="file" onChange={onFileChange.bind(this)} /> 
                <button onClick={() => {updateLogo(this)}}> 
                  Upload! 
                </button> 
            </div> 
          {fileData()} 
        </div> 
             {/* <DropzoneArea maxFiles={1}
              multiple={false}
        onChange={handleChange.bind(this)}
        />
         <Button variant="outlined" color="primary"  className={classes.saveButtonLogo} onClick={() => {updateLogo(this)}}  >
                    Save
                    </Button> */}
            </div>
          </div>
        </Modal>
      </div>
    </Paper>
  );
}
